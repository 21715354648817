import React, { useState, useEffect, useCallback } from 'react';
import { Alert, Button, Form, Table, Badge, Spinner } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";

const RECAPTCHA_SITE_KEY = "6LcAtDAqAAAAAMNx50QAUgt7hRkYRObbmCrEkpil";

const ReputationChecker = () => {
  const [ipAddress, setIpAddress] = useState('');
  const [results, setResults] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [delistLoading, setDelistLoading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const isValidIPv4 = (ip) => {
    const ipv4Regex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return ipv4Regex.test(ip);
  };

  const isPrivateIP = (ip) => {
    const privateIPRanges = [
      /^10\./,
      /^172\.(1[6-9]|2[0-9]|3[0-1])\./,
      /^192\.168\./,
      /^127\./,
      /^169\.254\./,
    ];
    return privateIPRanges.some((range) => range.test(ip));
  };

  const getRiskLevel = (status) => {
    if (status === 'RBL') return 'ALTO_RIESGO';
    switch (status) {
      case 'spam':
      case 'phishing':
      case 'troyano':
      case 'ransomware':
      case 'virus':
        return 'ALTO_RIESGO';
      case 'marketing':
      case 'rbl':
        return 'RIESGO_MEDIO';
      default:
        return 'BAJO_RIESGO';
    }
  };

  const handleSubmit = useCallback(async (event, ipToCheck = ipAddress) => {
    if (event) event.preventDefault();
    setLoading(true);
    setError('');
    setResults(null);

    if (!isValidIPv4(ipToCheck) || isPrivateIP(ipToCheck)) {
      setError('Dirección IP no válida');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`https://app.clisec.cl/iprep/reputation-check?ip=${encodeURIComponent(ipToCheck)}`);
      const data = await response.json();

      const isDeactivated = data.message === 'ip desactivada';
      const noRecords = data.message === 'No se encontraron notas para la IP proporcionada';

      const description = isDeactivated
        ? 'IP desbloqueada del antispam'
        : (noRecords
          ? `No se encontraron bloqueos para la IP indicada ${ipToCheck}`
          : (data.status === 'RBL'
            ? 'IP Bloqueada en DNSBL Clisec'
            : `IP utilizada ocasionalmente para enviar ${data.status}`));

      const riskLevel = isDeactivated || noRecords
        ? 'BAJO_RIESGO'
        : getRiskLevel(data.status);

      setResults({
        ipAddress: ipToCheck,
        riskLevel,
        description,
        showDelistButton: !isDeactivated && !noRecords,
        noRecords
      });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
      setError('Error al obtener la información de la dirección IP. Por favor, inténtalo de nuevo más tarde.');
    } finally {
      setLoading(false);
    }
  }, [ipAddress]);

  useEffect(() => {
    const path = window.location.pathname;
    const ipFromPath = path.split('/').pop();
    if (isValidIPv4(ipFromPath)) {
      setIpAddress(ipFromPath);
      handleSubmit(null, ipFromPath);
    }
  }, [handleSubmit]);

  // const handleDelist = async () => {
  //   if (!recaptchaToken) {
  //     setError('Por favor, complete el captcha antes de solicitar el desbloqueo.');
  //     return;
  //   }

  //   setDelistLoading(true);
  //   try {
  //     const response = await fetch('https://app.clisec.cl/iprep/delist', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({ 
  //         ipAddress,
  //         recaptchaToken 
  //       })
  //     });

  //     if (response.ok) {
  //       const data = await response.json();
  //       if (data.message === 'IP delistada') {
  //         // Refresh results
  //         handleSubmit(null, ipAddress);
  //       } else {
  //         setError('Error al solicitar desbloqueo');
  //       }
  //     } else {
  //       setError('Error al solicitar desbloqueo');
  //     }
  //   } catch (error) {
  //     console.error('Error en la solicitud:', error);
  //     setError('Error al solicitar desbloqueo');
  //   } finally {
  //     setDelistLoading(false);
  //     setRecaptchaToken(null);
  //   }
  // };

  const handleDelist = async () => {
    if (!recaptchaToken) {
      setError('Por favor, complete el captcha antes de solicitar el desbloqueo.');
      return;
    }
  
    setDelistLoading(true);
    setError('');  // Limpiamos cualquier error previo
    try {
      const response = await fetch('https://app.clisec.cl/iprep/delist', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ipAddress,
          recaptchaToken,
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
  
        if (data.message === 'IP delistada') {
          // Refresh results
          handleSubmit(null, ipAddress);
        } else if (data.message === 'Solicitud pendiente de aprobación.') {
          // Mostrar mensaje adecuado para la solicitud pendiente
          setResults({
            ipAddress,
            riskLevel: 'BAJO_RIESGO', // Ajustar según el riesgo que quieras mostrar
            description: 'La solicitud de desbloqueo está pendiente de aprobación.',
            showDelistButton: false, // No mostramos más el botón porque ya se solicitó
          });
        } else {
          setError('Error al solicitar desbloqueo');
        }
      } else {
        setError('Error al solicitar desbloqueo');
      }
    } catch (error) {
      console.error('Error en la solicitud:', error);
      setError('Error al solicitar desbloqueo');
    } finally {
      setDelistLoading(false);
      setRecaptchaToken(null);
    }
  };

    const onReCAPTCHAChange = (token) => {
    setRecaptchaToken(token);
  };

  return (
    <div
      className="banner-area banner-style-three text-light text-default section-small-platform-2"
      style={{
        backgroundImage: "url(assets/img/stars_stream.svg)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 col-md-12">
            <div className="text-center mb-4">
              <a href="/" className="d-flex flex-column align-items-center">
                <img src="/assets/img/logo.svg" alt="Clisec" className="mb-3" />
              </a>
              <h2 className="mb-3">Reputation Checker</h2>
              <p>
                ¿Tienes problemas para enviar correos electrónicos? <br />
                ¿Necesitas comprobar si una dirección IP se encuentra bloqueada?
              </p>
            </div>
            <Form
              id="reputation-check-form"
              className="mb-4"
              onSubmit={handleSubmit}
            >
              <Form.Group className="mb-3">
                <Form.Control
                  type="text"
                  placeholder="Ingrese una dirección IP"
                  className="text-center"
                  value={ipAddress}
                  onChange={(e) => setIpAddress(e.target.value)}
                  required
                />
              </Form.Group>
              <div id="submit-container">
                <Button type="submit" disabled={loading}>
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Buscar"
                  )}
                </Button>
              </div>
            </Form>
            <div className="text-center mb-3">
              <Button
                variant="secondary"
                onClick={() => handleSubmit(null, '8.8.8.8')}                
              >
                Try 8.8.8.8
              </Button>
            </div>
            {error && <Alert variant="danger">{error}</Alert>}
            {results && (
              <div className="mt-4 text-center">
                <h3 className="mb-3">
                  Resultados para{" "}
                  <span className="font-weight-bold">{results.ipAddress}</span>
                </h3>
                <div className="table-responsive">
                  <Table style={{ color: "white" }}>
                    <tbody>
                      <tr>
                        <th>Nivel de Riesgo:</th>
                        <td>
                          <Badge
                            bg={
                              results.riskLevel === "ALTO_RIESGO"
                                ? "danger"
                                : results.riskLevel === "RIESGO_MEDIO"
                                ? "warning"
                                : "success"
                            }
                          >
                            {results.riskLevel}
                          </Badge>
                        </td>
                      </tr>
                      <tr>
                        <th>Descripción:</th>
                        <td>{results.description}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                {results.showDelistButton && (
                  <div className="d-flex flex-column align-items-center mt-3">
                    <ReCAPTCHA
                      sitekey={RECAPTCHA_SITE_KEY}
                      onChange={onReCAPTCHAChange}
                    />
                    <Button
                      variant="warning"
                      onClick={handleDelist}
                      disabled={delistLoading || !recaptchaToken}
                      className="mt-3"
                    >
                      {delistLoading ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "Solicitar Desbloqueo"
                      )}
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReputationChecker;